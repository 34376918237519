import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class CanonicalService {
	constructor(private readonly router: Router) {}

	public watchForCanonical() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				document.querySelector('link[rel="canonical"]')?.setAttribute('href', window.location.href);
				document.querySelector('meta[property="og:url"]')?.setAttribute('content', window.location.href);
			}
		});
	}
}
