import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private currentUserService: CurrentUserService,
		private spinnerService: NgxSpinnerService,
	) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		this.spinnerService.show().then();
		return this.handleRequest(req, next).pipe(
			catchError(error => {
				return throwError(() => error);
			}),
			finalize(() => {
				this.spinnerService.hide().then();
			}),
		);
	}

	private handleRequest(req: HttpRequest<unknown>, next: HttpHandler) {
		const token = this.currentUserService.getToken();

		if (req.headers.has('X-NO-AUTH')) {
			return next.handle(req);
		}

		const headers: HttpHeaders = req.headers
			.set('Authorization', token?.getJwtToken() ? 'Bearer ' + token?.getJwtToken() : 'PUBLIC')
			.set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
			.set('Pragma', 'no-cache')
			.set('Expires', '0');
		const authReq = req.clone({ headers: headers });
		return next.handle(authReq);
	}
}
